import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {link} from "../../host-store";
import {token} from "../../../App";

export const abbreviationQuery = createApi({
    reducerPath: 'abbreviationQuery',
    baseQuery: fetchBaseQuery({
        baseUrl: link
    }),
    tagTypes: ["Abbreviation"],
    endpoints: (builder) => ({
        getAbbreviations: builder.query({
            query: () => ({
                url: '/legal-form?sort=title',
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ["Abbreviation"]
        }),
        postAbbreviationTag: builder.mutation({
            query: ({title}) => ({
                url: `/legal-form`,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(title)
            }),
            invalidatesTags: ["Abbreviation"]
        }),
        getAbbreviationTag: builder.query({
            query: (id) => ({
                url: `/legal-form/${id}`,
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ["Abbreviation"]
        }),
        putAbbreviationTag: builder.mutation({
            query: ({title, id}) => ({
                url: `/legal-form/${id}`,
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(title)
            }),
            invalidatesTags: ["Abbreviation"]
        }),
        deleteAbbreviationTag: builder.mutation({
            query: ({id}) => ({
                url: `/legal-form/${id}`,
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }),
            invalidatesTags: ["Abbreviation"]
        }),
    })
})

export const {useGetAbbreviationsQuery, usePostAbbreviationTagMutation, useLazyGetAbbreviationTagQuery,
usePutAbbreviationTagMutation, useDeleteAbbreviationTagMutation} = abbreviationQuery